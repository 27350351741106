import stands from 'configs/stands.json';

/**
 * @description Get current stand.
 * @returns {'test' | 'cert' | 'prod'} - Stand.
 */
export function getCurrentStand(): 'test' | 'cert' | 'prod' {
    // временное решение для m.online.rosbank.ru
    // todo: убрать после закрытия m.online.rosbank.ru
    if (location.origin === 'https://m.online.rosbank.ru') {
        return 'prod';
    }
    return (Object.keys(stands).find(
        (stand) =>
            stands[stand].url === location.origin ||
            stands[stand].partners.includes(location.origin),
    ) || process.env.REACT_APP_STAND) as 'test' | 'cert' | 'prod';
}
