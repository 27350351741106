/**
 * Browser interface.
 */
export type Browser = {
    /**
     * Name of browser.
     */
    name: string;
    /**
     * Version of browser.
     */
    version: string;
};

/**
 * @description Detect name and version of browser used.
 * @returns {Browser} Object as {@link Browser}.
 */
export const getBrowserUsed = (): Browser => {
    const { userAgent } = navigator;
    const result = { name: '', version: '' };
    if (userAgent.includes('Firefox/')) {
        const [name, version] = getNameAndVersionOfBrowser('Firefox');
        result.name = name;
        result.version = version;
    } else if (userAgent.includes('Edg/')) {
        const [name, version] = getNameAndVersionOfBrowser('Edg');
        result.name = name;
        result.version = version;
    } else if (userAgent.includes('YaBrowser/')) {
        const [name, version] = getNameAndVersionOfBrowser('YaBrowser');
        result.name = name;
        result.version = version;
    } else if (userAgent.includes('Chrome/')) {
        const [name, version] = getNameAndVersionOfBrowser('Chrome');
        result.name = name;
        result.version = version;
    } else if (userAgent.includes('CriOS/')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [name, version] = getNameAndVersionOfBrowser('CriOS', 'Chrome');
        result.name = name;
        result.version = version;
    } else if (userAgent.includes('Safari/')) {
        const [name, version] = getNameAndVersionOfBrowser('Safari');
        result.name = name;
        result.version = version;
    }
    return result;
};

function getNameAndVersionOfBrowser(
    browserName: string,
    replaceBrowserName = '',
): string[] {
    const { userAgent } = navigator;
    const browserVersion = userAgent.match(/Version\/(?<version>[0-9.]+)/)
        ?.groups?.version;
    if (browserVersion) {
        return [replaceBrowserName || browserName, browserVersion];
    }
    const [name, version] = userAgent
        .match(new RegExp(`${browserName}/[0-9.]+`))
        ?.pop()
        ?.trim()
        .split('/') || ['', ''];
    return [replaceBrowserName || name, version];
}
