/// <reference lib="webworker" />
import {
    ServiceWorkerInputMessage,
    ServiceWorkerOutputMessage,
} from 'serviceWorker/types';
import { serviceWorkerBroadcastChannel } from 'store/constants';
import { getBrowserUsed } from 'utils/getBrowserUsed';

/**
 * @description Channel.
 */
export interface Channel {
    /**
     * @description AddEventListener.
     */
    addEventListener(
        type: string,
        listener: (event: MessageEvent<ServiceWorkerOutputMessage>) => void,
        options?: boolean | AddEventListenerOptions,
    ): void;

    /**
     * @description PostMessage.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postMessage(message: ServiceWorkerInputMessage): void;
}
interface CustomExtendableMessageEvent<TypeMessage>
    extends ExtendableMessageEvent {
    data: TypeMessage;
}

interface CustomServiceWorkerGlobalScopeEventMap
    extends ServiceWorkerGlobalScopeEventMap {
    message: CustomExtendableMessageEvent<ServiceWorkerInputMessage>;
}

/**
 * @description Channel.
 */
export interface ServiceWorkerChannel {
    /**
     * @description AddEventListener.
     */
    addEventListener<K extends keyof CustomServiceWorkerGlobalScopeEventMap>(
        type: K,
        listener: (event: CustomServiceWorkerGlobalScopeEventMap[K]) => void,
        options?: boolean | AddEventListenerOptions,
    ): void;

    /**
     * @description PostMessage.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postMessage(message: ServiceWorkerOutputMessage): void;
}

/**
 * @description GetChannel.
 * @returns {Channel} Channel.
 */
export function getChannel(): Channel {
    const browserUsed = getBrowserUsed();

    if (
        !(
            browserUsed.name === 'Safari' &&
            browserUsed.version.match(/^10|11|12|13|14|15/)
        )
    )
        return new window.BroadcastChannel(serviceWorkerBroadcastChannel);
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        postMessage: (message: any) => {
            navigator.serviceWorker?.controller?.postMessage(message);
        },
        addEventListener(
            type: string,
            listener: (event: MessageEvent) => void,
        ) {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker?.ready.then(() => {
                    navigator.serviceWorker.onmessage = listener;
                });
            }
        },
    };
}
/**
 * @description GetChannel.
 * @param {ServiceWorkerGlobalScope}serviceWorkerGlobalScope - Clients.
 * @returns {Channel} Channel.
 */
export function getServiceWorkerChannel(
    serviceWorkerGlobalScope: ServiceWorkerGlobalScope,
): ServiceWorkerChannel {
    const browserUsed = getBrowserUsed();

    if (
        !(
            browserUsed.name === 'Safari' &&
            browserUsed.version.match(/^(10|11|12|13|14|15)/)
        )
    )
        return new BroadcastChannel(serviceWorkerBroadcastChannel);
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        postMessage: (message: any) => {
            serviceWorkerGlobalScope.clients.matchAll().then((clientList) => {
                if (clientList && clientList.length) {
                    clientList.forEach((client) => {
                        if (client.url.includes(location.host)) {
                            client.postMessage(message);
                        }
                    });
                }
            });
        },
        addEventListener<
            K extends keyof CustomServiceWorkerGlobalScopeEventMap,
        >(
            type: K,
            listener: (
                event: CustomServiceWorkerGlobalScopeEventMap[K],
            ) => void,
        ) {
            serviceWorkerGlobalScope.addEventListener(type, listener);
        },
    };
}
