/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ServiceError, ServiceMethods, Services } from 'serviceWorker/services';

export type ResponseWithHeaders<T> = T & {
    /**
     * Headers.
     */
    headers: { [k: string]: string };
};

/**
 * @description RequestData.
 */
export interface RequestData {
    /**
     * Headers.
     */
    headers?: any;
    /**
     * Query.
     */
    query?: any;
    /**
     * Body.
     */
    body?: any;
}

/**
 * ServiceResponse.
 */
export interface ResponseService<Data = any | null> {
    /**
     * Data.
     */
    data?: Data;
    /**
     * Result.
     */
    result: ResponseResult;
}

/**
 * Result of {@link ResponseService}.
 */
export interface ResponseResult {
    /**
     * Timestamp.
     */
    readonly timestamp?: Date;
    /**
     * Status.
     */
    status: number;
    /**
     * Code.
     */
    code: string;
    /**
     * Massage.
     */
    message: string;
}

/**
 * JSONApiResponse.
 */
export interface JSONApiResponse<T> {
    /**
     * Value.
     */
    value(): Promise<ResponseWithHeaders<T>>;
}

export const tokenExpiredError = ['OAUT.02999'];

/**
 * @description CurrentRequests.
 */
export class CurrentRequests {
    abortController: AbortController;

    /**
     * Service.
     */
    service: Service;

    /**
     * Method.
     */
    method: Method;

    type: QueueRequestType;

    /**
     * @param {string}service - = Service.
     * @param {string} method - Method.
     * @param {QueueRequestType} type - Type.
     * @param {AbortController} abortController - AbortController.
     * @description Constructor of CurrentRequests.
     */
    constructor(
        service: Service,
        method: Method,
        type: QueueRequestType,
        abortController: AbortController,
    ) {
        this.type = type;
        this.method = method;
        this.service = service;
        this.abortController = abortController;
    }
}

/**
 * @description CurrentFileRequests.
 */
export class CurrentFileRequests {
    abortController: AbortController;

    path: string;

    type: QueueRequestFileType;

    /**
     * @param {string} path - Path.
     * @param {QueueRequestFileType} type - Type.
     * @param {AbortController} abortController - AbortController.
     * @description Constructor of CurrentRequests.
     */
    constructor(
        path: string,
        type: QueueRequestFileType,
        abortController: AbortController,
    ) {
        this.type = type;
        this.path = path;

        this.abortController = abortController;
    }
}

/**
 *
 */
export interface ApiPostMessage {
    /**
     * @description TOKEN_IS_REFRESHED.
     */
    TOKEN_IS_REFRESHED?: ResponseWithHeaders<AuthTokenResult>;
    /**
     * @description TOKEN_IS_REFRESHED.
     */
    TOKEN_IS_NOT_REFRESHED?: ResponseWithHeaders<AuthTokenResult>;
}

/**
 * @description ApiQueueServicePostMessage.
 */
export interface ApiQueueServicePostMessage {
    /**
     * @description Uuid.
     */
    uuid: string;
}

/**
 * @description TokenServiceError.
 */
export class TokenServiceError extends ServiceError {
    /**
     * ErrorResponse.
     */
    errorResult: ResponseResult;

    /**
     * @description Constructor of TokenServiceError.
     * @param {string}code - Code.
     * @param {ResponseResult}errorResult - ErrorResult.
     * @param {string}message - Message.
     */
    constructor(code: string, errorResult: ResponseResult, message?: string) {
        super(code, message);
        this.errorResult = errorResult;
    }
}

export type QueueRequestStatus = 'success' | 'error' | 'pending';

export enum QueueRequestType {
    'default' = 'default',
    'pendingTokenRefresh' = 'pendingTokenRefresh',
    'pendingNetworkConnection' = 'pendingNetworkConnection',
}

export enum QueueRequestFileType {
    'default' = 'default',

    'pendingNetworkConnection' = 'pendingNetworkConnection',
}

export enum AbortReason {
    'connectionIsOffline' = 'connectionIsOffline',
    'tokenError' = 'tokenError',
    'tokenIsNotRefreshed' = 'tokenIsNotRefreshed',
    'isLogout' = 'isLogout',
    'globalError' = 'globalError',
}

export enum AbortReasonFile {
    'connectionIsOffline' = 'connectionIsOffline',
    'timeout' = 'timeout',
}

/**
 * @description QueueRequest.
 */
export interface QueueRequest<
    Request extends (...args: any[]) => Promise<ReturnType<Request>>,
> {
    /**
     * Service.
     */
    service: Service;

    /**
     * Method.
     */
    method: Method;

    /**
     * Request.
     */
    request: Request;
    /**
     * Status.
     */
    status: QueueRequestStatus;

    /**
     * Type.
     */
    type: QueueRequestType;

    /**
     * Response.
     */
    response: Response | null;
    /**
     * AbortReason.
     */
    abortReason?: AbortReason;
}

/**
 * @description QueueFileRequest.
 */
export interface QueueFileRequest<
    Request extends (...args: any[]) => Promise<ReturnType<Request>>,
> {
    /**
     * Request.
     */
    request: Request;
    /**
     * Status.
     */
    status: QueueRequestStatus;

    /**
     * Type.
     */
    type: QueueRequestFileType;

    /**
     * Response.
     */
    response: Response | null;
    /**
     * AbortReason.
     */
    abortReason?: AbortReasonFile;
}

export type TokenRefreshStatus =
    | 'TOKEN_IS_NOT_REFRESHED'
    | 'TOKEN_IS_REFRESHED'
    | null;

export type TokenRefreshData = {
    /**
     * Status.
     */
    status: TokenRefreshStatus;
    /**
     * Response.
     */
    response: AuthTokenResult | null;
};

/**
 *
 */
export interface OAuth2AccessToken {
    /**
     *
     * @type {number}
     * @memberof OAuth2AccessToken
     */
    expiresIn?: number;
    /**
     *
     * @type {string}
     * @memberof OAuth2AccessToken
     */
    tokenType?: string;
    /**
     * @description Specify the scope of the access request (non = basic) like scope separated by a space ' '. For examle: 'basic basic:read identication'.
     * @type {string}
     * @memberof OAuth2AccessToken
     */
    /**
     *
     */
    scope?: string;
    /**
     *
     * @type {object}
     * @memberof OAuth2AccessToken
     */
    additionalInformation?: object;
    /**
     *
     * @type {Date}
     * @memberof OAuth2AccessToken
     */
    expiration?: Date;
    /**
     *
     * @type {boolean}
     * @memberof OAuth2AccessToken
     */
    expired?: boolean;
}

/**
 *
 * @interface AuthTokenResult
 */
export interface AuthTokenResult {
    /**
     * ResponseResult.
     */
    result: ResponseResult;

    /**
     * OAuth2AccessToken.
     */
    data: OAuth2AccessToken;
}

/**
 * ServiceWorkerMessagePostMessage.
 */
export interface ServiceWorkerMessagePostMessage<
    Message extends string,
    MessageData = undefined,
> {
    /**
     *
     */
    message: Message;
    /**
     *
     */
    messageData?: MessageData;
}

interface SWStatus {
    activate?: string;
    install?: string;
}

/**
 * ServiceWorkerInputMessage.
 */
export interface ServiceWorkerOutputMessage {
    /**
     * Meta.
     */
    meta?: 'workbox-broadcast-update';
    /**
     * App.
     */
    app?:
        | ServiceWorkerMessagePostMessage<
              'hasRequestsWithNetworkProblemChanged',
              boolean
          >
        | ServiceWorkerMessagePostMessage<
              'tokenRefreshDataChanged',
              {
                  status: TokenRefreshStatus;
                  response: AuthTokenResult;
              }
          >
        | ServiceWorkerMessagePostMessage<'globalErrorChanged', ServiceError>
        | ServiceWorkerMessagePostMessage<'refreshToken'>
        | ServiceWorkerMessagePostMessage<'updateCache'>
        | ServiceWorkerMessagePostMessage<'mfModuleIsFailedLoad', string>
        | ServiceWorkerMessagePostMessage<'toSentry', SentryData>
        | ServiceWorkerMessagePostMessage<'swStatus', SWStatus>;
}

/**
 * @description Data for sentry.
 */
export interface SentryData {
    /**
     * @description Error data.
     */
    error: unknown;
    /**
     * @description Request URL.
     */
    requestUrl?: string;
    /**
     * @description Additional description of the error context.
     */
    description?: string;
    /**
     * @description Result code from response.
     */
    resultCode?: string;
    /**
     * @description Response ID.
     */
    responseId?: string;
    /**
     * @description Response Content-type.
     */
    contentType?: string;
}

/**
 * GlobalErrors.
 */
export interface GlobalErrors {
    /**
     * GlobalErrors.
     */
    allErrors: Record<string, string>;
    /**
     * TokenError.
     */
    tokenErrors: Record<string, string>;
    /**
     * LoginErrors.
     */
    loginErrors: Record<string, string>;
    /**
     * NotBlockingApp.
     */
    notBlockingApp: Record<string, string>;
}
/**
 * ServiceWorkerInputMessage.
 */
export interface ServiceWorkerInputMessage {
    /**
     * ServiceWorker.
     */
    serviceWorker?:
        | ServiceWorkerMessagePostMessage<'networkIsOffline'>
        | ServiceWorkerMessagePostMessage<'networkIsOnline'>
        | ServiceWorkerMessagePostMessage<'retryRequests'>
        | ServiceWorkerMessagePostMessage<'retryRequestsInBackground'>
        | ServiceWorkerMessagePostMessage<'clearData'>
        | ServiceWorkerMessagePostMessage<'init'>;
}

interface Browser {
    /**
     * Name of browser.
     */
    name: string;
    /**
     * Version of browser.
     */
    version: string;
}

interface Coordinates {
    /**
     * Latitude of coordinates.
     */
    latitude: string;
    /**
     * Longitude of coordinates.
     */
    longitude: string;
    /**
     * TimeZone user device.
     */
    timeZone: string;
    /**
     * Country user device.
     */
    country: string;
    /**
     * Region user device.
     */
    region: string;
}

enum DeviceTypes {
    mobile = 'mobile',
    desktop = 'desktop',
    tablet = 'tablet',
}

/**
 * DeviseInfoData.
 */
export interface DeviseInfoData {
    /**
     * Coordinates.
     */
    readonly coordinates: Coordinates;

    /**
     * Browser name on device.
     */
    readonly browser: Browser;

    /**
     * Operating system name on device.
     */
    readonly os: string;

    /**
     * Type of device.
     */
    readonly deviceType: DeviceTypes;
}

export enum CacheNames {
    js = 'js',
    css = 'css',
    html = 'html',
    configsJson = 'configsJson',
    stands = 'stands',
    wasm = 'wasm',
    images = 'images',
    fonts = 'fonts',
}
export type Service = keyof typeof Services | 'unknown';
export type Method = keyof typeof ServiceMethods | 'unknown';
