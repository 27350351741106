/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ResponseError } from 'remotes/@api/authorization_v4';
import stands from 'configs/stands.json';
import { CacheNames } from 'serviceWorker/types';
import { isNotHashedFile } from 'serviceWorker/utils/utils';
import { getCurrentStand } from 'utils/getCurrentStand';

export enum LocalStorageKeys {
    lastUserActivity = 'lastUserActivity',
    clearActiveTabFlag = 'clearActiveTabFlag',
    currentLanguage = 'currentLanguage',
    isLogout = 'isLogout',
    appVersion = 'appVersion',
    tein = 'tein',
    sein = 'sein',
    newMfVersions = 'newMfVersions',
    currentMfVersions = 'currentMfVersions',
    userInfo = 'userInfo',
    needCheckNewVersionApp = 'needCheckNewVersionApp',
    activeTabCount = 'activeTabCount',
    sessionId = 'sessionId',
    needNotification = 'needNotification',
    isNeedToResetPin = 'isNeedToResetPin',
    appUpdateConfirmShow = 'appUpdateConfirmShow',
    needBiometric = 'needBiometric',
    canBiometricAuth = 'canBiometricAuth',
    operationsHistory = 'operationsHistory',
}

export enum SessionStorageKeys {
    isActiveTab = 'isActiveTab',
    startAuth = 'startAuth',
    isOpened = 'isOpened',
    resetCount = 'resetCount',
}

/**
 * @description Check error is ResponseError.
 * @param {any} err - Error.
 * @returns {boolean} Return true if error is ResponseError.
 */
export function isServiceResponse(err: any): err is ResponseError {
    return typeof err === 'object' && err !== null && 'response' in err;
}

/**
 * @param {boolean} isLoading - IsLoading.
 * @description Change flag of loading app.
 */
export function setAppIsLoading(isLoading: boolean) {
    document.documentElement.dataset.isLoading = String(isLoading);
}

/**
 * @description GetAppIsLoading.
 * @returns {boolean} AppIsLoading.
 */
export function getAppIsLoading(): boolean {
    return document.documentElement.dataset.isLoading === 'true';
}

/**
 * @description Set timestamp of last user activity (click, touch and other user actions).
 * @param {number} timestamp - Last user activity in timestamp.
 */
export function setLastUserActivityLocalStorage(timestamp: number) {
    localStorage.setItem(LocalStorageKeys.lastUserActivity, String(timestamp));
}

/**
 * @description Get timestamp of last user activity.
 * @returns {number} Return timestamp.
 */
export function getLastUserActivityLocalStorage(): number {
    const lastUserActivity = localStorage.getItem(
        LocalStorageKeys.lastUserActivity,
    );
    if (lastUserActivity) {
        return +lastUserActivity;
    }
    return 0;
}

/**
 * @description Get is active tab in session storage.
 * @returns {boolean} Return IsActiveTab.
 */
export function getIsActiveTabSessionStorage(): boolean {
    return sessionStorage.getItem(SessionStorageKeys.isActiveTab) === 'true';
}

/**
 * @description Set is active tab in session storage.
 * @param {boolean} isActiveTab - IsActiveTab.
 */
export function setIsActiveTabSessionStorage(isActiveTab: boolean) {
    sessionStorage.setItem(SessionStorageKeys.isActiveTab, String(isActiveTab));
}

/**
 * @description ClearLastUserActivity.
 */
export function clearLastUserActivity() {
    localStorage.removeItem(LocalStorageKeys.lastUserActivity);
}

/**
 * @description SetLogout.
 * @param {boolean} isLogout - IsLogout.
 */
export function setLogoutLocalStorage(isLogout: boolean) {
    localStorage.setItem(LocalStorageKeys.isLogout, String(isLogout));
}

/**
 * @description GetLogout.
 * @returns {boolean} IsLogout.
 */
export function getLogoutLocalStorage(): boolean {
    return !window.localStorage.getItem(LocalStorageKeys.isLogout)
        ? true
        : window.localStorage.getItem(LocalStorageKeys.isLogout) === 'true';
}

/**
 * @description Get token expires in timestamp.
 * @returns {number } Timestamp.
 */
export function getTokenExpiresInLocalStorage(): number {
    return localStorage.getItem(LocalStorageKeys.tein)
        ? Number(localStorage.getItem(LocalStorageKeys.tein))
        : 0;
}

/**
 * @description Set token expires in timestamp.
 * @param {number} timestamp - Token expires in timestamp.
 */
export function setTokenExpiresInLocalStorage(timestamp: number) {
    window.localStorage.setItem(LocalStorageKeys.tein, String(timestamp));
}

/**
 * @description Set session expires in timestamp.
 * @param {number} timestamp - Session expires in timestamp.
 */
export function setSessionExpiresInLocalStorage(timestamp: number) {
    window.localStorage.setItem(LocalStorageKeys.sein, String(timestamp));
}

/**
 * @description Get is need pin reset.
 * @returns {boolean } Flag.
 */
export function getIsNeedToResetPinLocalStorage(): boolean {
    return (
        window.localStorage.getItem(LocalStorageKeys.isNeedToResetPin) ===
        'true'
    );
}

/**
 * @description Get session expires in timestamp.
 * @returns {number} Timestamp.
 */
export function getSessionExpiresInLocalStorage(): number {
    return localStorage.getItem(LocalStorageKeys.sein)
        ? Number(localStorage.getItem(LocalStorageKeys.sein))
        : 0;
}

/**
 * @description StartAuth.
 */
export function setStartAuthSessionStorage() {
    sessionStorage.setItem(SessionStorageKeys.startAuth, String(Date.now()));
}

/**
 * @description GetStartAuth.
 * @returns {number} - StartAuth.
 */
export function getStartAuth(): number {
    return Number(sessionStorage.getItem(SessionStorageKeys.startAuth) || 0);
}

/**
 * @description ClearStartAuth.
 */
export function clearStartAuth() {
    sessionStorage.removeItem(SessionStorageKeys.startAuth);
}

/**
 * @description OnOpenTab.
 */
export function onOpenTab() {
    const isOpened = sessionStorage.getItem(SessionStorageKeys.isOpened);
    if (!isOpened) {
        const activeTabCount = localStorage.getItem(
            LocalStorageKeys.activeTabCount,
        );
        sessionStorage.setItem(SessionStorageKeys.isOpened, 'true');
        localStorage.setItem(
            LocalStorageKeys.activeTabCount,
            String(activeTabCount ? +activeTabCount + 1 : 1),
        );
    }
}

/**
 * @description OnCloseTab.
 */
export function onCloseTab() {
    sessionStorage.removeItem(SessionStorageKeys.startAuth);
    const activeTabCount = localStorage.getItem(
        LocalStorageKeys.activeTabCount,
    );
    localStorage.setItem(
        LocalStorageKeys.activeTabCount,
        String(activeTabCount ? +activeTabCount - 1 : 0),
    );
}

/**
 * @description GetActiveTabCount.
 * @returns {number} - ActiveTabCount.
 */
export function getActiveTabCount(): number {
    const activeTabCount = localStorage.getItem(
        LocalStorageKeys.activeTabCount,
    );
    return activeTabCount ? +activeTabCount : 0;
}

/**
 * @description SetUserInfoLocalStorage.
 * @param {string} userInfo - UserInfo.
 */
export function setUserInfoLocalStorage(userInfo: string) {
    localStorage.setItem(LocalStorageKeys.userInfo, userInfo);
}

/**
 * @description DeleteUserInfoLocalStorage.
 */
export function deleteUserInfoLocalStorage() {
    localStorage.removeItem(LocalStorageKeys.userInfo);
}

/**
 * @description DeleteUserHistoryData.
 */
export function deleteUserHistoryData() {
    localStorage.removeItem(LocalStorageKeys.operationsHistory);
}

/**
 * @description GetNeedCheckNewVersionAppLocalStorage.
 * @returns {boolean} NeedCheckNewVersionApp.
 */
export function getNeedCheckNewVersionAppLocalStorage() {
    return (
        localStorage.getItem(LocalStorageKeys.needCheckNewVersionApp) === 'true'
    );
}

/**
 * @description SetNeedCheckNewVersionAppLocalStorage.
 * @param {boolean} needCheckNewVersionApp - NeedCheckNewVersionApp.
 */
export function setNeedCheckNewVersionAppLocalStorage(
    needCheckNewVersionApp: boolean,
) {
    localStorage.setItem(
        LocalStorageKeys.needCheckNewVersionApp,
        String(needCheckNewVersionApp),
    );
}

/**
 * @description GetAppUpdateConfirmShow.
 * @returns {boolean} AppUpdateConfirmShow.
 */
export function getAppUpdateConfirmShow() {
    return (
        localStorage.getItem(LocalStorageKeys.appUpdateConfirmShow) === 'true'
    );
}

/**
 * @description SetAppUpdateConfirmShow.
 * @param {boolean} appUpdateConfirmShow - AppUpdateConfirmShow.
 */
export function setAppUpdateConfirmShow(appUpdateConfirmShow: boolean) {
    document.documentElement.dataset.showUpdateConfirm =
        String(appUpdateConfirmShow);
    localStorage.setItem(
        LocalStorageKeys.appUpdateConfirmShow,
        String(appUpdateConfirmShow),
    );
}

/**
 * @description GetAppVersion.
 * @param {Record<string,string>} mfVersions - MfVersions.
 * @returns {string} AppVersion.
 */
export async function getAppVersion(
    mfVersions?: Record<string, string>,
): Promise<string> {
    let currentMfVersions: Record<string, string> = {};
    const date = new Date();
    const currentMonth = date.getUTCMonth() + 1;
    const currentYear = +date.getUTCFullYear().toString().slice(-2);

    if (mfVersions) {
        currentMfVersions = mfVersions;
    } else {
        try {
            const appVersionResponse = await fetch(
                String(sessionStorage.getItem('REACT_APP_VERSION')),
            );
            currentMfVersions = await appVersionResponse.json();
        } catch (e) {
            //
        }
    }

    if (
        getCurrentMfVersions() &&
        Object.keys(currentMfVersions).length &&
        getCurrentMfVersions() !==
            window.btoa(JSON.stringify(currentMfVersions))
    ) {
        setNewMfVersions(window.btoa(JSON.stringify(currentMfVersions)));
    } else if (Object.keys(currentMfVersions).length) {
        setNewMfVersions(window.btoa(JSON.stringify(currentMfVersions)));
        setCurrentMfVersions(window.btoa(JSON.stringify(currentMfVersions)));
    }
    const versions: number[] = [];
    Object.values(currentMfVersions).forEach((mfVersion) => {
        const [year, month, version] = mfVersion.split('.').slice(-3);
        versions.push(
            +year === currentYear && +month === currentMonth ? +version : 0,
        );
    });

    const appYear = currentYear;
    const appMonth = currentMonth;
    const appVersion = versions.reduce((a, b) => a + b, 0);

    return `${appYear}.${appMonth}.${appVersion}`;
}

/**
 * @description GetNeedBiometric.
 * @returns {boolean} NeedBiometric.
 */
export function getNeedBiometric() {
    return localStorage.getItem(LocalStorageKeys.needBiometric) === 'true';
}

/**
 * @description SetNeedBiometric.
 * @param {boolean} needBiometric - NeedBiometric.
 */
export function setNeedBiometric(needBiometric: boolean) {
    localStorage.setItem(LocalStorageKeys.needBiometric, String(needBiometric));
}

/**
 * @description GetCanBiometricAuth.
 * @returns {boolean} CanBiometricAuth.
 */
export function getCanBiometricAuth(): boolean {
    return localStorage.getItem(LocalStorageKeys.canBiometricAuth) === 'true';
}

/**
 * @description SetResetCount.
 * @param {number} resetCount - ResetCount.
 */
export function setResetCount(resetCount: number) {
    sessionStorage.setItem(SessionStorageKeys.resetCount, String(resetCount));
}

/**
 * @description GetResetCount.
 * @returns {boolean} ResetCount.
 */
export function getResetCount(): number {
    return sessionStorage.getItem(SessionStorageKeys.resetCount) !== null
        ? Number(sessionStorage.getItem(SessionStorageKeys.resetCount))
        : 0;
}

/**
 * @description CanBiometricAuth.
 * @param {boolean} canBiometricAuth - CanBiometricAuth.
 */
export function setCanBiometricAuth(canBiometricAuth: boolean) {
    localStorage.setItem(
        LocalStorageKeys.canBiometricAuth,
        String(canBiometricAuth),
    );
}

/**
 * @description SetNeedNotification.
 * @param {number} needNotification - NeedNotification.
 */
export function setNeedNotification(needNotification: boolean) {
    localStorage.setItem(
        LocalStorageKeys.needNotification,
        String(needNotification),
    );
}

/**
 * @description GetNeedNotification.
 * @returns {boolean} NeedNotification.
 */
export function getNeedNotification(): boolean {
    if (localStorage.getItem(LocalStorageKeys.needNotification) === null) {
        setNeedNotification(true);
        return true;
    }
    return localStorage.getItem(LocalStorageKeys.needNotification) === 'true';
}

/**
 * @description SetAppVersionLocalStorage.
 * @param {string} appVersion - AppVersion.
 */
export function setAppVersionLocalStorage(appVersion: string) {
    localStorage.setItem(LocalStorageKeys.appVersion, String(appVersion));
}

/**
 * @description GetAppVersionLocalStorage.
 * @returns {string} AppVersion.
 */
export function getAppVersionLocalStorage(): string {
    if (localStorage.getItem(LocalStorageKeys.appVersion) === null) {
        return '';
    }
    return String(localStorage.getItem(LocalStorageKeys.appVersion));
}

/**
 * @description SetNewMfVersions.
 * @param {string} newMfVersions - NewMfVersions.
 */
export function setNewMfVersions(newMfVersions: string) {
    localStorage.setItem(LocalStorageKeys.newMfVersions, String(newMfVersions));
}

/**
 * @description GetNewMfVersions.
 * @returns {string} NewMfVersions.
 */
export function getNewMfVersions(): string {
    if (localStorage.getItem(LocalStorageKeys.newMfVersions) === null) {
        return '';
    }
    return String(localStorage.getItem(LocalStorageKeys.newMfVersions));
}

/**
 * @description SetCurrentMfVersions.
 * @param {string} currentMfVersions - CurrentMfVersions.
 */
export function setCurrentMfVersions(currentMfVersions: string) {
    localStorage.setItem(
        LocalStorageKeys.currentMfVersions,
        String(currentMfVersions),
    );
}

/**
 * @description GetCurrentMfVersions.
 * @returns {string} CurrentMfVersions.
 */
export function getCurrentMfVersions(): string {
    if (localStorage.getItem(LocalStorageKeys.currentMfVersions) === null) {
        return '';
    }
    return String(localStorage.getItem(LocalStorageKeys.currentMfVersions));
}

/**
 * @description CheckNewVersionApp.
 * @param {boolean} isNeedToShowConfirm - IsNeedToShowConfirm.
 */
export async function checkNewVersionApp(isNeedToShowConfirm = true) {
    const appVersion = await getAppVersion();
    const appVersionLocalStorage = getAppVersionLocalStorage();
    const appIsLoading = getAppIsLoading();
    if (appVersionLocalStorage && appVersionLocalStorage !== appVersion) {
        if (appIsLoading) {
            document.documentElement.dataset.isUpdating = 'true';
            await clearCachesOnUpdate();
            setAppVersionLocalStorage(appVersion);
            if (
                process.env.NODE_ENV === 'development' ||
                sessionStorage.getItem('REACT_APP_STAND') === 'test' ||
                sessionStorage.getItem('REACT_APP_STAND') === 'cert'
            ) {
                // eslint-disable-next-line no-console
                console.log('--refresh--');
            }
            window.stop();
            window.location.reload();
        } else if (isNeedToShowConfirm) {
            // todo: добавлено для выявления дефекта отображения окна обновления. убрать в дальнейшем
            if (sessionStorage.getItem('REACT_APP_STAND') === 'cert') {
                // eslint-disable-next-line no-console
                console.trace(
                    `Показать окно обновления
                    Request appVersion - ${appVersion},
                    Local storage appVersion - ${appVersionLocalStorage},
                    currentMfVersions - ${getCurrentMfVersions()},
                    newMfVersions - ${getNewMfVersions()}`,
                );
            }

            setAppUpdateConfirmShow(true);
        }
    } else if (!getAppVersionLocalStorage()) {
        setAppVersionLocalStorage(appVersion);
    }
}

/**
 * @description ClearCachesOnUpdate.
 * @returns {Promise<void>} ClearCachesOnUpdate.
 */
export async function clearCachesOnUpdate(): Promise<void> {
    const mfsDiffVersion = getMfsDiffVersion();
    const newMfVersions = getNewMfVersions();
    const currentStand = getCurrentStand();

    const cacheNames = Object.values(CacheNames).filter(
        (cacheName) => cacheName !== CacheNames.wasm,
    );
    const standHost =
        process.env.NODE_ENV === 'development'
            ? 'https://localhost:3000'
            : stands[currentStand].url;
    const regExpHost = new RegExp(`${standHost}`);
    await Promise.all(
        cacheNames.map((cacheName) =>
            caches.open(cacheName).then(async (cacheStorage) => {
                const requests = await cacheStorage.keys();
                await Promise.all(
                    requests.map(async (request) => {
                        const requestMfName = regExpHost.test(request.url)
                            ? 'host'
                            : String(
                                  request.url.match(/mf\/(?<mfName>[\w-]+)\//)
                                      ?.groups?.mfName,
                              );

                        if (
                            mfsDiffVersion?.includes(requestMfName) ||
                            isNotHashedFile(request.url)
                        ) {
                            const response = await cacheStorage.match(request);
                            if (response) {
                                await cacheStorage.delete(request);
                            }
                        }
                        return Promise.resolve();
                    }),
                );
            }),
        ),
    );

    setNewMfVersions(newMfVersions);
    setCurrentMfVersions(newMfVersions);
}

/**
 * @description GetMode.
 * @returns {'pwa' | 'browser'} - Mode of app.
 */
export function getMode() {
    return window.matchMedia('(display-mode: standalone)').matches
        ? 'pwa'
        : 'browser';
}

function getMfsDiffVersion() {
    const newMfVersions = JSON.parse(window.atob(getNewMfVersions()));
    const currentMfVersions = JSON.parse(window.atob(getCurrentMfVersions()));
    const mfsDiffVersion: string[] = [];
    Object.entries(currentMfVersions).forEach(([mfName, version]) => {
        if (newMfVersions[mfName] !== version) {
            mfsDiffVersion.push(mfName);
        }
    });
    return mfsDiffVersion;
}
