import certConfig from './configs/app.cert.config.json';
import prodConfig from './configs/app.prod.config.json';
import testConfig from './configs/app.test.config.json';
import stands from './configs/stands.json';
import { CacheNames } from 'serviceWorker/types';
import { getCurrentStand } from 'utils/getCurrentStand';
import { checkNewVersionApp } from 'utils/utils';

const currentStand = getCurrentStand();

let appConfig = testConfig;
if (currentStand === 'cert') {
    appConfig = certConfig;
}
if (currentStand === 'prod') {
    appConfig = prodConfig;
}
await initEnvs();

// import('./sentryInit');

if (!localStorage.getItem('swVersion')) {
    await navigator.serviceWorker
        ?.getRegistrations()
        .then(async (registrations) => {
            const activeServiceWorker = registrations.find(
                (registration) => registration.active?.scriptURL,
            );
            if (
                activeServiceWorker &&
                activeServiceWorker.active &&
                /service-worker.[0-9.]+.js/.test(
                    activeServiceWorker.active.scriptURL,
                )
            ) {
                await activeServiceWorker.unregister();
                const cacheNames: string[] = Object.values(CacheNames);
                const cachesList = await caches.keys();
                await Promise.all(
                    cachesList.map((cache) => {
                        if (!cacheNames.includes(cache)) {
                            return caches.delete(cache);
                        }
                        return Promise.resolve();
                    }),
                );
                window.location.reload();
            }
        });
}

await navigator.serviceWorker?.ready;
await checkNewVersionApp(false);

await initDesignTokens();

import('./bootstrap');

async function initEnvs() {
    const envs = await generateDeployENVs();
    let envFromLocalServer;
    if (process.env.NODE_ENV === 'development') {
        const response = await fetch(`${location.origin}/getEnvs`);
        envFromLocalServer = await response.json();
    }
    Object.keys(envs)
        .filter((envName) => !envName.match(/_PORT/))
        .filter((envName) => !envName.match(/FROM_CI/))
        .forEach((envName) => {
            sessionStorage.setItem(
                envName,
                process.env.NODE_ENV === 'development'
                    ? String(envFromLocalServer[envName])
                    : String(envs[envName])?.replace(
                          currentStand === 'prod'
                              ? 'https://web.evo.rosbank.ru'
                              : `https://web-${currentStand}.evo.rosbank.ru`,
                          '',
                      ),
            );
        });

    sessionStorage.setItem(
        'REACT_APP_X_VERSION',
        String(process.env.REACT_APP_X_VERSION),
    );
    sessionStorage.setItem('currentStand', currentStand);
    if (process.env.NODE_ENV === 'development') {
        sessionStorage.setItem(
            'REACT_APP_API_BASE_PATH',
            String(process.env.REACT_APP_API_PROXY_PATH),
        );
    }
    // временное решение для m.online.rosbank.ru
    // todo: убрать после закрытия m.online.rosbank.ru
    if (location.origin === 'https://m.online.rosbank.ru') {
        sessionStorage.setItem(
            'REACT_APP_API_BASE_PATH',
            String('https://m.online.rosbank.ru/backend'),
        );
    }
    if (process.env.NODE_ENV === 'development') {
        sessionStorage.setItem(
            'REACT_APP_APIM_BASE_PATH',
            String(process.env.REACT_APP_APIM_PROXY_PATH),
        );
    }

    // fix problem with prod cookie for partners, send request to partner origin
    if (
        stands[currentStand].partners.includes(location.origin) &&
        currentStand === 'prod'
    ) {
        const apiBasePathUrl = new URL(
            sessionStorage.getItem('REACT_APP_API_BASE_PATH') ??
                location.origin,
        );
        const partnersBackend = apiBasePathUrl
            .toString()
            .replace(apiBasePathUrl.origin, location.origin)
            .replace(/\/$/, '');

        sessionStorage.setItem(
            'REACT_APP_API_BASE_PATH',
            String(partnersBackend),
        );
    }
}

async function generateDeployENVs() {
    const { mfLinks, api, ...otherENVs } = appConfig;
    return {
        ...mfLinks,
        FROM_CI: true,
        ...api,
        ...otherENVs,
    };
}

// enum DeviceTypes {
//     mobile = 'mobile',
//     desktop = 'desktop',
//     tablet = 'tablet',
// }

// /**
//  * @description GetDeviceType.
//  * @returns {DeviceTypes} DeviceTypes.
//  */
// function getDeviceType(): DeviceTypes {
//     const ua = navigator.userAgent;
//     if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
//         return DeviceTypes.tablet;
//     }
//
//     if (
//         /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
//             ua,
//         )
//     ) {
//         return DeviceTypes.mobile;
//     }
//     return DeviceTypes.desktop;
// }
export {};

async function initDesignTokens() {
    let designTokenCssFileName = 'design-tokens.min.css';
    if (stands[currentStand].partners.includes(window.location.origin)) {
        const { Partner } = await import('remotes/@partners/Partner');
        const { getPartnerResource } = await import(
            'remotes/@partners/getPartnerResource'
        );
        designTokenCssFileName = getPartnerResource('design-tokens.min.css', {
            [Partner.BANKI_RU]: 'design-tokens-banki-ru.min.css',
        });
    }

    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = 'designToken';
    link.rel = 'stylesheet';
    link.type = 'text/css';

    link.href = `${sessionStorage.getItem(
        'REACT_APP_DESIGN_TOKENS',
    )}/${designTokenCssFileName}`;

    head.appendChild(link);
}
