/* eslint-disable  @typescript-eslint/no-explicit-any */

import { RouteMatchCallbackOptions } from 'workbox-core';
import stands from 'configs/stands.json';
import { AbortReason, CacheNames } from 'serviceWorker/types';

export * from './getChanelMessage';

/**
 *
 * @description IsResponseError.
 * @param {any} err - Error;.
 * @returns {Response} Err - Return is service error.
 */
export function isResponseError(err: any): err is ResponseError {
    return typeof err === 'object' && err !== null && 'response' in err;
}

/**
 *
 * @description IsResponse.
 * @param {any} response - Response.
 * @returns {Response} IsResponse.
 */
export function isResponse(response: any): response is Response {
    return (
        typeof response === 'object' &&
        'json' in response &&
        'status' in response
    );
}

/**
 *
 * @description IsDefaultError.
 * @param {any} err - Error;.
 * @returns {Response} Err - Return is service error.
 */
export function isAbortError<T = AbortReason>(
    err: any,
): err is { abortReason: T } {
    return typeof err === 'object' && err !== null && 'abortReason' in err;
}

/**
 * @description IsDefaultError.
 * @param {any} err - Error;.
 * @returns {Error} Err - Return default error.
 */
export function isDefaultError(err: any): err is Error {
    return err instanceof Error;
}

/**
 * @description ResponseError.
 */
export class ResponseError extends Error {
    name = 'ResponseError' as const;

    /**
     * @description Constructor of ResponseError.
     * @param {Response}response - Response.
     * @param {string} msg - Msg.
     */
    constructor(public response: Response, msg?: string) {
        super(msg);
    }
}

/**
 * @description IsNotHashedFile.
 * @param {string} url - Url.
 * @returns {boolean} IsNotHashedFile.
 */
export function isNotHashedFile(url: string): boolean {
    return !/[a-z0-9]+\.chunk\.[a-zA-Z]+$|\/[a-z0-9]+\.[a-z0-9]+\.[a-zA-Z]+$|favicon|[0-9_]+\.[a-zA-Z]+$/.test(
        url,
    );
}

/**
 * @description RouteFileMatch.
 * @param {RouteMatchCallbackOptions}options - Options.
 * @param {CacheNames}cacheName - CacheName.
 * @returns {boolean} RouteFileMatch.
 */
export function routeFileMatch(
    options: RouteMatchCallbackOptions,
    cacheName: CacheNames,
): boolean {
    const { url, request } = options;
    if (
        request.destination === 'document' &&
        /service-worker/.test(url.href) &&
        /firebase-messaging-sw/.test(url.href) &&
        /swagger-ui/.test(url.href)
    ) {
        return false;
    }
    switch (cacheName) {
        case CacheNames.css: {
            return /\.css$/.test(url.href);
        }
        case CacheNames.js: {
            return /\.js$/.test(url.href);
        }
        case CacheNames.configsJson: {
            return (
                /manifest\.json$/.test(url.href) ||
                /globalErrors\.json$/.test(url.href)
            );
        }
        case CacheNames.images: {
            return /\.(?:png|jpg|jpeg|svg|gif|ico)$/.test(url.href);
        }
        case CacheNames.stands: {
            return /stands\.json$/.test(url.href);
        }
        case CacheNames.wasm: {
            return /\.wasm$/.test(url.href);
        }
        case CacheNames.html: {
            return request.destination === 'document';
        }
        case CacheNames.fonts: {
            return /\.(?:woff2|woff)$/.test(url.href);
        }
        default: {
            return false;
        }
    }
}

/**
 * @description RouteBackendMatch.
 * @param {Request}request - Request.
 * @returns {boolean} RouteBackendMatch.
 */
export function routeBackendMatch(request: Request): boolean {
    const { url, destination, method } = request;
    switch (method) {
        case 'GET':
        case 'POST':
        case 'DELETE':
        case 'PUT':
        case 'HEAD':
        case 'PATCH': {
            return (
                destination !== 'document' &&
                !/getEnvs/.test(url) &&
                !/omni.rosbank.ru/.test(url) &&
                !/group-ib/.test(url) &&
                !/apimbackend/.test(url) &&
                !/tokenrbapitest/.test(url) &&
                !/\.[a-zA-Z]{1}\w{1,9}($|\?)/.test(url) &&
                !/\/backend\/audit/.test(url) &&
                !/firebase/.test(url) &&
                !/fcmregistrations/.test(url) &&
                !/edna/.test(url) &&
                !/audit\/v\d+\/.*/.test(url) &&
                !/nalogrequest/.test(url) &&
                !/swagger-ui/.test(url) &&
                !/trace.evo.rosbank.ru/.test(url)
            );
        }
        default: {
            return false;
        }
    }
}

/**
 * @description GetCurrentStand.
 * @returns {'test'|'cert'|'prod'} Stand.
 */
export function getCurrentStand(): 'test' | 'cert' | 'prod' {
    if (location.origin === 'https://online.rosbank.ru') {
        return 'prod';
    }
    if (
        stands.test.url === location.origin ||
        stands.test.partners.includes(location.origin)
    ) {
        return 'test';
    }
    if (
        stands.cert.url === location.origin ||
        stands.cert.partners.includes(location.origin)
    ) {
        return 'cert';
    }
    if (
        stands.prod.url === location.origin ||
        stands.prod.partners.includes(location.origin)
    ) {
        return 'prod';
    }
    return 'test';
}

/**
 * @description IsTestEnvironment.
 * @returns {boolean} IsTestEnvironment.
 */
export function getIsTestEnvironment(): boolean {
    const currentStand = getCurrentStand();
    return (
        process.env.NODE_ENV === 'development' ||
        currentStand === 'cert' ||
        currentStand === 'test'
    );
}

// eslint-disable-next-line jsdoc/require-returns-check
/**
 * @description GetCacheName.
 * @param {Request} request - Request.
 * @returns {CacheNames} - CacheNames.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line consistent-return
export function getCacheName(request: Request): CacheNames {
    switch (true) {
        case request.url.endsWith('.js'): {
            return CacheNames.js;
        }
        case request.url.endsWith('.html') ||
            request.destination === 'document': {
            return CacheNames.html;
        }
        case request.url.endsWith('.css'): {
            return CacheNames.css;
        }
        case request.url.endsWith('.wasm'): {
            return CacheNames.wasm;
        }
        case /manifest\.json$/.test(request.url): {
            return CacheNames.configsJson;
        }
        case !!request.url.match(/\.(?:png|jpg|jpeg|svg|gif|ico)$/): {
            return CacheNames.images;
        }
        case !!request.url.match(/\.(?:woff|woff2)$/): {
            return CacheNames.fonts;
        }
        case !!request.url.match(/stands\.json$/): {
            return CacheNames.stands;
        }
        default: {
            //
        }
    }
}

/**
 * @description Log.
 * @param {string} header - Header.
 * @param {any[]} messages - Mesages.
 */
export const log = (header: string, ...messages: any[]) => {
    if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_STAND === 'test'
    ) {
        // eslint-disable-next-line no-console
        console.log(`${header}================================start`);

        messages.forEach((message) => {
            // eslint-disable-next-line no-console
            console.log(message);
        });
        // eslint-disable-next-line no-console
        console.log(`${header}================================end`);
    }
};
